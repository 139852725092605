<template>
 <div class="bg-white rounded-md shadow-sm p-6 mb-4">
    <div class="flex justify-between">
      <p  class="font-bold text-base">
        Soportes
      </p>
      <div v-tooltip="'Agregar soporte'" @click="openMaximizable()">
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" preserveAspectRatio="xMidYMid meet"
             viewBox="0 0 1024 1024"
             class="cursor-pointer text-blue-600"
        >
          <path fill="currentColor"
                d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM544 472c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v108H372c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h108v108c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V644h108c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H544V472z"/>
        </svg>
      </div>
    </div>
    <div style="max-height: 22rem;" class="overflow-y-auto grid grid-cols-1 mt-4">
      <div v-for="(soporte, i) in data.paths" :key="i">
        <div class="flex justify-between my-2">
          <div class="flex">
            <i class="pi pi-file-pdf mr-2 text-red-600"></i>
            <p>{{ soporte.fileName }}</p>
          </div>
          <div>
            <div class="flex">
              <button @click="verPdf(soporte.urlPath)" class="ease-in duration-300" >
                <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
    </template>
  </Dialog>
</template>
<script>
import { computed, ref } from 'vue'
import PdfService from '../../../../../../../services/pdf.service'
import { useToast } from 'primevue/usetoast'
import derechosPeticionStore from '../../../../../../../store/derechosPeticion.store'
import FilePond from '../../../../../../uploadFilePoundNew/index.vue'
import filepondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'
import { useRoute } from 'vue-router'

export default {
  name: 'soportesDerecho',
  components: {
    FilePond
  },
  setup () {
    const route = useRoute()
    const adjunto = computed(() => filepondStore.getters.adjunto)
    const data = computed(() => {
      return derechosPeticionStore.getters._derecho
    })
    const displayMaximizable = ref(false)
    const _PdfService = new PdfService()
    const verPdf = (path) => {
      _PdfService.ver({ path })
    }
    const openMaximizable = () => {
      displayMaximizable.value = true
    }
    const toast = useToast()

    const onUpload = () => {
      toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 })
    }
    const pond = ref()
    const paths = ref([])
    const title = ref('')

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(async () => {
        paths.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        await _PdfService.create({
          tipoNombre: 'derecho_de_peticion',
          tipo: 5,
          base: route.params.id,
          fileName: paths.value[0].fileName,
          urlPath: paths.value[0].urlPath
        })
        await derechosPeticionStore.dispatch('find', route.params.id)
        displayMaximizable.value = false
        title.value = ''
      })
    }
    return {
      data,
      verPdf,
      onUpload,
      openMaximizable,
      displayMaximizable,
      closeMaximizable,
      pond,
      title
    }
  }
}
</script>
<style scoped>
::v-deep(.p-fileupload-buttonbar) {
  display: none;
}
::v-deep(.p-fileupload .p-fileupload-content ) {
  border: none;
}
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::v-deep(.p-button.p-fileupload-choose):hover {
  background-color: white;
  color: black;
  font-weight: 600;
}
::v-deep(.p-button.p-fileupload-choose):focus {
  box-shadow: none;
}
::v-deep(.p-button .p-button-icon-left) {
  display: none;
}
::v-deep(.p-button:enabled:hover .p-dialog .p-dialog-footer button) {
  width: 100% !important;
}

</style>
